import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Location from 'components/layout/Posts/Location'
import Tiles from 'components/layout/Sections/Pages/Realizacje/Tiles'
import ModalSlider from 'components/layout/Sections/Pages/Realizacje/ModalSlider'

import Navigation from 'components/layout/Navigation/index'

import { removeWhitespaces } from 'utils/removeWhitespaces'

const breadcrumbs = {
  mobile: [
    {
      label: 'Inspiracje',
      link: '/inspiracje/',
    },
    {
      label: 'Realizacje',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Inspiracje',
      link: '/inspiracje/',
    },
    {
      label: 'Realizacje',
    },
  ],
}

const RealizacjePage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.realizacje
  const PAGE_SEO = data?.wpPage?.seo

  const [sliderGalleryNumber, setSliderGalleryNumber] = React.useState(0)
  const [sliderInitialSlide, setSliderInitialSlide] = React.useState(0)
  const [sliderVisible, setSliderVisible] = React.useState(false)

  const handleGalleryZoom = (galleryNumber: number, slideNumber: number) => {
    setSliderGalleryNumber(galleryNumber)
    setSliderInitialSlide(slideNumber)
    setSliderVisible(true)
  }

  const handleSliderClose = () => {
    setSliderVisible(false)
  }

  const GALLERIES = React.useMemo(
    () =>
      PAGE?.realisationSingle?.map((item, index) => ({
        galleryNumber: index,
        galleryId: removeWhitespaces(
          item?.realisationSingleTileTitle?.toLowerCase()
        ),
        galleryDescription: item.realisationSingleTitle,
        gallery: item?.realisationSingleImg?.map((singleImg) => ({
          img: singleImg?.realisationSingleImgSingle?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: singleImg?.realisationSingleImgSingle?.altText,
        })),
        onGalleryZoom: handleGalleryZoom,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.realisationBannerImg?.localFile?.childImageSharp
            ?.gatsbyImageData ||
          data?.heroImgMobile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.realisationBannerImg?.localFile?.childImageSharp
            ?.gatsbyImageData ||
          data?.heroImgMobile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.realisationBannerText || 'Realizacje'}
        headingDesktop={PAGE?.realisationBannerText || 'Realizacje'}
        breadcrumbs={breadcrumbs}
      />
      <Location label="Realizacje" centered />
      <Tiles galleries={GALLERIES} />
      <ModalSlider
        isVisible={sliderVisible}
        slides={GALLERIES[sliderGalleryNumber].gallery}
        initialSlideIndex={sliderInitialSlide}
        close={handleSliderClose}
      />
    </Layout>
  )
}

export default RealizacjePage

export const query = graphql`
  query RealizacjePage {
    heroImgMobile: file(name: { eq: "placeholder-img-black" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    wpPage(slug: { eq: "realizacje" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      realizacje {
        realisationBannerText
        realisationBannerImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        realisationSingle {
          realisationSingleTitle
          realisationSingleTileTitle
          realisationSingleImg {
            realisationSingleImgSingle {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [JPG, AUTO]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
