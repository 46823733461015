import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import { Text } from 'components/shared/typography'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import arrowIcon from 'assets/icons/arrow-right-white.svg'
import closeIcon from 'assets/icons/close.svg'
import 'swiper/css'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type Slide = {
  img: ImageDataLike
  alt: string
}

type Props = {
  slides: Slide[]
  initialSlideIndex: number
  isVisible: boolean
  close: () => void
}

const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background: rgba(0, 0, 0, 0.725);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  .swiper {
    width: 100%;
    height: 100%;
    .swiper-wrapper {
      width: 100%;
      height: 100%;
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const SwiperWrapper = styled.div``

const SlideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 768px;
  height: 85%;
  margin: 0 auto;
  cursor: grab;
  .gatsby-image-wrapper {
    max-height: 100%;
    width: fit-content;
  }

  ${({ theme }) => theme.media.xl.min} {
    max-width: 1024px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    max-width: 1240px;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  transition: 200ms ease-in-out;
`

const ControlButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  width: 40px;
  height: 40px;
  transition: 200ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  &:hover {
  }
  z-index: 1;
`

const PrevButton = styled(ControlButton)`
  left: 0;
  div {
    transform: rotate(180deg) translateX(2px);
  }

  ${({ theme }) => theme.media.lg.min} {
    left: 50%;
    transform: translateX(-474px) translateY(-50%);
  }

  ${({ theme }) => theme.media.xl.min} {
    transform: translateX(-570px) translateY(-50%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    transform: translateX(-690px) translateY(-50%);
  }
`

const NextButton = styled(ControlButton)`
  right: 0;
  div {
    transform: translateX(2px);
  }

  ${({ theme }) => theme.media.lg.min} {
    right: 50%;
    transform: translateX(474px) translateY(-50%);
  }

  ${({ theme }) => theme.media.xl.min} {
    transform: translateX(570px) translateY(-50%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    transform: translateX(690px) translateY(-50%);
  }
`

const SlidesCountWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
`

const ModalSlider: React.FC<Props> = ({
  slides,
  initialSlideIndex,
  isVisible,
  close,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(initialSlideIndex)

  const { xl } = useBreakpoint()

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  useEffect(() => {
    swiper?.slideTo(initialSlideIndex)
    setActiveSlideIndex(initialSlideIndex)
  }, [initialSlideIndex])

  useEffect(() => {
    const handleKeyboardNavigation = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close()
      }

      if (event.key === 'ArrowRight') {
        goNext()
      }

      if (event.key === 'ArrowLeft') {
        goPrev()
      }
    }

    window.addEventListener('keydown', handleKeyboardNavigation)
    return () => window.removeEventListener('keydown', handleKeyboardNavigation)
  }, [slides])

  return (
    <Overlay isVisible={isVisible}>
      {slides && (
        <Swiper
          onSwiper={(initSwiper) => setSwiper(initSwiper)}
          slidesPerView={1}
          onSlideChange={() => setActiveSlideIndex(swiper?.realIndex!)}
        >
          <SwiperWrapper>
            {slides.map(({ img, alt }, index) => (
              <SwiperSlide key={`${alt}-image${index}`}>
                <SlideWrapper>
                  <LazyImage src={img} alt={alt} objectFit="contain" />
                </SlideWrapper>
              </SwiperSlide>
            ))}
          </SwiperWrapper>
        </Swiper>
      )}
      <CloseButton type="button" onClick={close} aria-label="close">
        <Icon src={closeIcon} alt="" size={xl ? 24 : 18} />
      </CloseButton>
      {activeSlideIndex > 0 && (
        <PrevButton type="button" onClick={goPrev}>
          <Icon src={arrowIcon} size={xl ? 20 : 18} alt="arrow-left" />
        </PrevButton>
      )}
      {activeSlideIndex !== slides.length - 1 && (
        <NextButton type="button" onClick={goNext}>
          <Icon src={arrowIcon} size={xl ? 20 : 18} alt="arrow-right" />
        </NextButton>
      )}
      <SlidesCountWrapper>
        <Text
          size={xl ? 18 : 16}
          weight={500}
          themecolor="gray"
          margin="0"
          dangerouslySetInnerHTML={{
            __html: `${activeSlideIndex + 1} / ${slides.length}`,
          }}
        />
      </SlidesCountWrapper>
    </Overlay>
  )
}

export default ModalSlider
