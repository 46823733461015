import React from 'react'
import styled from 'styled-components'

import { ImageDataLike } from 'gatsby-plugin-image'
import { Heading } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'
import Container from 'components/shared/container'

type GalleryItem = {
  img: ImageDataLike
  alt: string
}

type Gallery = {
  galleryNumber: number
  galleryId?: string
  galleryDescription: string
  gallery: GalleryItem[]
  onGalleryZoom: (galleryNumber: number, slideNumber: number) => void
}

type Props = {
  galleries: Gallery[]
}

const Section = styled.section`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;

  ${({ theme }) => theme.media.sm.min} {
    margin-bottom: 65px;
  }

  ${({ theme }) => theme.media.lg.min} {
    :first-child {
      margin-top: 50px;
    }
  }
`

const TilesWrapper = styled.div`
  ${({ theme }) => theme.media.sm.min} {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 15px;
  }

  ${({ theme }) => theme.media.lg.min} {
    grid-template-columns: repeat(3, auto);
    gap: 25px;
  }
`

const TileSingle = styled.div`
  margin-bottom: 15px;
  cursor: zoom-in;
`

const Tiles: React.FC<Props> = ({ galleries }) => {
  return (
    <Container>
      {galleries?.map(
        ({
          galleryId,
          galleryNumber,
          galleryDescription,
          gallery,
          onGalleryZoom,
        }) => (
          <Section key={`realizations-${galleryNumber}`} id={galleryId}>
            <Heading
              as="h2"
              size={30}
              themecolor="black"
              align="center"
              transform="uppercase"
              dangerouslySetInnerHTML={{ __html: galleryDescription }}
            />
            <TilesWrapper>
              {gallery?.map(({ img, alt }, index) => (
                <TileSingle
                  key={`gallery-${galleryNumber}-tile-${index}`}
                  onClick={() => onGalleryZoom(galleryNumber, index)}
                >
                  <LazyImage src={img} alt={alt} />
                </TileSingle>
              ))}
            </TilesWrapper>
          </Section>
        )
      )}
    </Container>
  )
}

export default Tiles
